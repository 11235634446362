<template>
  <div class="order">
    <van-tabs v-model="tabsActive"
              animated
              swipeable
              color="#07c160"
              class="order-tabs"
              @change="change">
      <van-tab title="待受理"
               name="0">
        <van-cell-group v-if="list0.length">
          <van-cell v-for="(item,index) in list0"
                    :key="index"
                    is-link
                    :arrow-direction="item.active ? 'up' : 'down'"
                    :title="'申请人：'+item.Name"
                    @click="cellClick(index,list0)">

            <template #label
                      v-if="item.active">
              <div>受理网点：{{item.DotName}}</div>
              <div>客户经理：{{item.Khjl}}</div>
              <div>小区名称：{{item.VillageName}}</div>
              <div>申请时间：{{item.CreateTime}}</div>
            </template>
          </van-cell>
        </van-cell-group>
        <van-empty v-else
                   description="暂无待受理的申请" />
      </van-tab>
      <van-tab title="受理中"
               name="1">
        <van-cell-group v-if="list1.length">
          <van-cell v-for="(item,index) in list1"
                    :key="index"
                    is-link
                    :arrow-direction="item.active ? 'up' : 'down'"
                    :title="item.Name"
                    @click="cellClick(index,list1)">

            <template #label
                      v-if="item.active">
              <div>受理网点:{{item.DotName}}</div>
              <div>客户经理:{{item.Khjl}}</div>
              <div>小区名称:{{item.VillageName}}</div>
              <div>申请时间:{{item.CreateTime}}</div>
            </template>
          </van-cell>
        </van-cell-group>
        <van-empty v-else
                   description="暂无受理中的申请" />
      </van-tab>
      <van-tab title="已完成"
               name="2">
        <van-cell-group v-if="list2.length">
          <van-cell v-for="(item,index) in list2"
                    :key="index"
                    is-link
                    :arrow-direction="item.active ? 'up' : 'down'"
                    :title="item.Name"
                    @click="cellClick(index,list2)">

            <template #label
                      v-if="item.active">
              <div>受理网点:{{item.DotName}}</div>
              <div>客户经理:{{item.Khjl}}</div>
              <div>详细地址:{{item.VillageName}}</div>
              <div>申请时间:{{item.CreateTime}}</div>
            </template>
          </van-cell>
        </van-cell-group>
        <van-empty v-else
                   description="暂无已完成的申请" />
      </van-tab>
    </van-tabs>

  </div>
</template>

<script>
import { loadLog } from '@/api/public'

export default {
  name: 'order',
  data () {
    return {
      tabsActive: '0',
      tabbarActive: 'order',
      list0: [],
      list1: [],
      list2: [],
    };
  },
  created () {
    this.$store.state.tabBar = this.$route.name;
    this.loadLog({ state: 0 });
  },
  methods: {
    change (name) {
      this.loadLog({ state: name });
    },
    loadLog (query) {
      loadLog(query).then((res) => {
        debugger;
        res.data.forEach((item) => {
          item.CreateTime = item.CreateTime.replace('T', ' ');
          item.active = false;
        });
        this['list' + query.state] = res.data;
      }).catch(e => {
        console.log(e);
      })
    },
    cellClick (index, list) {
      list.forEach((item, i) => {
        if (i == index) {
          list[index].active = !list[index].active;
        } else {
          item.active = false;
        }
      })
    }
  },
}
</script>
<style lang="less">
.order-tabs {
  .van-tabs__content {
    min-height: 100vh;
  }
}
</style>
